import axios from "axios";
const CryptoJS = require("crypto-js");

// account and password
const account = process.env.VUE_APP_ACCOUNT;
const password = process.env.VUE_APP_PASSWORD;

// API endpoints
const authEndpoint = process.env.VUE_APP_AUTH_ENDPOINT;
const deviceListEndpoint = process.env.VUE_APP_DEVICE_LIST_ENDPOINT;
const trackInfoEndpoint = process.env.VUE_APP_TRACK_INFO_ENDPOINT;

// Function to authenticate and get access token
export const getAuthen = async () => {
  try {
    // Generate timestamp and signature
    const timestamp = Math.floor(new Date().getTime() / 1000);
    const passwordHash = CryptoJS.MD5(password).toString();
    const signature = CryptoJS.MD5(passwordHash + timestamp).toString();

    // Send request to auth endpoint
    const response = await axios.get(
      `${authEndpoint}?time=${timestamp}&account=${account}&signature=${signature}`
    );
    // Return the access token
    return response.data.record.access_token;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

// Function to get device list
export const getDeviceList = async (accessToken) => {
  try {
    // Send request to device list endpoint
    const response = await axios.get(
      `${deviceListEndpoint}?access_token=${accessToken}`
    );
    // Return the device list
    return response.data.record;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

// Function to get track info
export const getTrackInfo = async (accessToken, imeis) => {
  try {
    // Convert imeis array to string
    const imeisString = imeis.join(",");
    // Send request to track info endpoint
    const response = await axios.get(
      `${trackInfoEndpoint}?access_token=${accessToken}&imeis=${imeisString}`
    );
    // Return the track info
    return response.data.record;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

// Function to call sequence
export const getDeviceInfos = async (imeis) => {
  try {
    // Get access token
    const accessToken = await getAuthen();
    // Get track info
    const response = await getTrackInfo(
      accessToken,
      Array.isArray(imeis) ? imeis : [imeis]
    );
    return response;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

// Function to get devices
export const getDevices = async () => {
  try {
    const accessToken = await getAuthen();
    return await getDeviceList(accessToken);
    
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
addEventListener("fetch", (event) => {
  event.respondWith(handleRequest(event.request));
});

async function handleRequest(request) {
  // Your existing CORS handling code...

  // Example of adding headers to a response
  const corsHeaders = {
    "Access-Control-Allow-Origin": "*", // Replace with your allowed origins
    "Access-Control-Allow-Methods": "GET, HEAD, POST, OPTIONS",
    "Access-Control-Allow-Headers": "Content-Type, Authorization", // Add your required headers
  };

  // Check for CORS headers in the incoming request
  if (
    request.headers.get("Origin") !== null &&
    request.headers.get("Access-Control-Request-Method") !== null &&
    request.headers.get("Access-Control-Request-Headers") !== null
  ) {
    // Handle CORS preflight requests.
    return new Response(null, {
      headers: {
        ...corsHeaders,
        "Access-Control-Allow-Headers": request.headers.get(
          "Access-Control-Request-Headers"
        ),
      },
    });
  } else {
    // Handle standard OPTIONS request.
    return new Response(null, {
      headers: {
        Allow: "GET, HEAD, POST, OPTIONS",
        ...corsHeaders,
      },
    });
  }
}