<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import mapStylesJson from './mapStyle.json'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import { getDeviceInfos, getDevices } from '../../../api/protrack.js'
import InfiniteLoading from 'vue-infinite-loading';
import VehicleImage from '@/assets/images/vehicle-blue.png'; // adjust the path to match your project structure

export default {
  components: {
    Layout,
    GmapCluster,
    InfiniteLoading,
  },
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      // device data 
      devices: [],
      search: '',
      checkedImeis: [],
      isLoading: false,
      vehicleImage: VehicleImage,
      // Google Maps settings
      mapStyles: mapStylesJson,
      center: { lat: 3.0858152, lng: 102.5847612 },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: '',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -50
        }
      },
      markers: [],
    }
  },
  computed: {
    // Compute filtered devices
    filteredDevices() {
      if (!this.search) return this.devices;
      return this.devices.filter(device =>
        device.imei.toString().includes(this.search)
      );
    },
  },
  methods: {
    getRotatedIcon(marker) {
      return {
        url: this.rotateImage(marker.rotation),
        scaledSize: { width: 50, height: 50 },
      };
    },
    rotateImage(rotation) {
      // Create an image
      let img = new Image();
      img.crossOrigin = "anonymous";
      img.src = this.vehicleImage

      // Create a canvas
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');

      // Ensure the canvas is the right size to hold the rotated image
      canvas.width = img.width;
      canvas.height = img.height;

      // Rotate and draw the image onto the canvas
      ctx.translate(img.width / 2, img.height / 2);
      ctx.rotate(rotation * Math.PI / 180);
      ctx.drawImage(img, -img.width / 2, -img.height / 2);

      // Return the canvas's data URL
      return canvas.toDataURL();
    },


    async infiniteHandler($state) {
      if (this.isLoading) return;
      this.isLoading = true;

      try {
        const newDevices = await getDevices(this.devices.length, 9);
        if (newDevices.length) {
          const uniqueNewDevices = newDevices.filter(newDevice => 
            !this.devices.some(device => device.imei === newDevice.imei));
          this.devices = [...this.devices, ...uniqueNewDevices];
          $state.loaded();
        } else {
          $state.complete();
        }
      } catch (error) {
        console.error("Error fetching devices:", error);
        $state.complete();
      } finally {
        this.isLoading = false;
      }
    },

    async populateVehicleDetails(imei) {
      const result = await getDeviceInfos(imei);
      return this.formatVehicleDetails(result[0]);
    },
    formatVehicleDetails(details) {
      // Template literals used for cleaner string concatenation
      return `
        <div class="infolist">
          <div class="device-prototype-item">
            <i class="prototype-item-icon iconfont bt-icon-dianliangchi f12"></i>
            <span class="fb">Battery:</span> ${details.battery}
          </div>
          <div class="device-prototype-item">
            <i class="prototype-item-icon iconfont bt-icon-imei f12"></i>
            <span class="fb">IMEI:</span>
            <span class="monitor-map-popup-imei">
              ${details.imei}
              <i tip-title="Copy" class="mouseTips base-icon-btn copy-icon mouseCopy" data-imei="${details.imei}"></i>
            </span>
          </div>
          <div class="device-prototype-item">
            <span class="fb">
              <i class="prototype-item-icon iconfont bt-icon-weizhi f12"></i>
              Coordinate:
            </span>
            <a href="http://map.google.com/maps?q=${details.latitude},${details.longitude}" target="_blank">
              ${details.latitude},${details.longitude}
            </a>
          </div>
        </div>
      `;
    },
    async toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      let vehicle = await this.populateVehicleDetails(marker.imei); // pass the IMEI to the function
      this.infoOptions.content = vehicle;
      this.infoWinOpen = this.currentMidx === idx ? !this.infoWinOpen : true;
      this.currentMidx = idx;
    }
  },
  async created() {
    this.devices = await getDevices();
  },
  watch: {
    checkedImeis: {
      async handler(newImeis) {
        this.markers = await Promise.all(newImeis.map(async (imei) => {
          const details = await getDeviceInfos(imei);
          return {
            position: { 
              lat: details[0].latitude, 
              lng: details[0].longitude 
            },
            rotation: details[0].course,
            infoText: `<strong>IMEI:</strong> ${details[0].imei}`,
            imei,
          };
        }));
      },
      deep: true
    },
  },
};
</script>

<style>
.container-fluid {
  position: relative;
}

#device-box {
  position: absolute;
  z-index: 999;
  left: 15px;
  top: 70px;
  width: 300px;
  height: 500px;
}

#device-box-title {
  width: 100%;
  background: #45161f;
  padding: 5px;
  color: #fff;
  padding-left: 7px;
  font-weight: bold;
}

#device-box-body {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 5px;
  color: #000;
  opacity: 0.8;
}

.vehicle-label {
  padding-left: 8px;
  font-size: 14px;
}
</style>

<template>
  <Layout>
    <div id="device-box">
    <div id="device-box-title" style="display: flex; justify-content: space-between; align-items: center;">
      <div><i class="iconfont"></i>My Devices</div>
      <input type="text" v-model="search" placeholder="Search imei" style="position: sticky; top: 0;">
    </div>
    <div id="device-box-body" style="height: 400px; overflow-y: auto;">
      <div v-for="device in filteredDevices" :key="device.imei">
        <input type="checkbox" :id="'vehicle' + device.imei" :name="'vehicle' + device.imei" :value="device.imei"
          v-model="checkedImeis">
        <label class="vehicle-label" :for="'vehicle' + device.imei">{{ device.devicename }} {{ device.imei }}</label><br>
      </div>
      <infinite-loading @infinite="infiniteHandler"></infinite-loading>
    </div>
  </div>
    <GmapMap :center="center" :zoom="8" style="width: 80dvw; height: 50dvw" :options="{ styles: mapStyles }">
      <GmapCluster>
        <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
          @closeclick="infoWinOpen = false" />
        <GmapMarker 
          :key="index" 
            v-for="(m, index) in markers" 
            :position="m.position" 
            :clickable="true"
            :icon="getRotatedIcon(m)"
            :draggable="true"
          @click="toggleInfoWindow(m, index)">
        </GmapMarker>
      </GmapCluster>
    </GmapMap>
  </Layout>
</template>